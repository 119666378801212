import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HtmlIcon from "@mui/icons-material/Html";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function CustomizedMenus({
  handleSubmit,
  deriDate,
  ngaData,
  dataCheck,
}) {
  const [open, setOpen] = useState(false);
  const handleHtmlClick = () => handleSubmit(1);
  const handleExcelClick = () => handleSubmit(2);
  const handlePdfClick = () => handleSubmit(3);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button
        style={{
          width: "94%",
          color: "white",
          margin: "10px",
          backgroundColor: dataCheck ? "#1c80cf" : "#C6C6C6",
        }}
        disabled={!ngaData || !deriDate}
        variant="contained"
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {!ngaData || !deriDate
          ? "Duhet te plotesoni datat"
          : "Zgjidhni tipin e Raportit"}
      </Button>
      {open && (
        <Paper style={{ position: "absolute", left: 15, right: 15 }}>
          <MenuItem onClick={handlePdfClick} disableRipple>
            <PictureAsPdfIcon />
            PDF Document
          </MenuItem>
          <MenuItem onClick={handleHtmlClick} disableRipple>
            <HtmlIcon />
            HTML Document
          </MenuItem>
          <MenuItem onClick={handleExcelClick} disableRipple>
            <InsertDriveFileIcon />
            EXEL Document (Shkarko)
          </MenuItem>
        </Paper>
      )}
    </div>
  );
}
