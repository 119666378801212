import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print80Skontrino.scss";
import QRCode from "qrcode.react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "N/A";
};

const DynamicTableSkontrino80 = ({
  rows,
  state,
  fisVerificationUrl,
  fatureThjesht,
}) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <React.Fragment key={rowNumber}>
        <tr>
          <td className="columnsPershkrim">{info.Pershkrim}</td>
          <td className="columnsPershkrim">{info.Sasia} x</td>
          <td className="columnsPershkrim">{formatNumber(info.Cmimi)}</td>
          <td className="columnsVlera">{formatNumber(info.Vlera)}</td>
        </tr>
        <tr>
          <td colSpan={4}>
            <div className="dashed-line"></div>
          </td>
        </tr>
      </React.Fragment>
    );
  });

  const groupedRows = rows.reduce((acc, info) => {
    const key = info.Tvsh ?? 0;
    if (!acc[key]) {
      acc[key] = { totalVleraPaTvsh: 0, totalVlera: 0 };
    }
    acc[key].totalVleraPaTvsh += info.Vlera_Pa_Tvsh;
    acc[key].totalVlera += info.Vlera;
    return acc;
  }, {});

  const DisplayDataTable2 = Object.entries(groupedRows).map(
    ([tvsh, totals]) => {
      let tipi = "";
      if (tvsh === "6") {
        tipi = "D";
      } else if (tvsh === "20") {
        tipi = "B";
      } else if (tvsh === "10") {
        tipi = "C";
      } else {
        tipi = "A";
      }

      return (
        <tr key={tvsh}>
          <td>{tipi}</td>
          <td>{tvsh}</td>
          <td>{formatNumber(totals.totalVleraPaTvsh)}</td>
          <td>{formatNumber(totals.totalVlera)}</td>
        </tr>
      );
    }
  );

  const sums = {
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
  };
  return (
    <table className="hide-border">
      <thead>
        <tr
          className="table-head"
          style={{
            textAlign: "center",
          }}
        >
          <th className="th">Artikull</th>
          <th className="th">Sasia</th>
          <th className="th">Cmimi</th>
          <th className="th">Vlera</th>
        </tr>
      </thead>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>

      <tbody>{DisplayData}</tbody>

      <tr>
        <td className="columnsTotal flex-col" colSpan={1}>
          <h1>TOTALI</h1>
        </td>
        <td colSpan={2}></td>
        <td className="columnsTotal columnsvleracenter" colSpan={3}>
          <h1>{sums.Vlera}</h1>
        </td>
        <td colSpan={4}></td>
      </tr>
      {state.NSLF && (
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>

            <div className="footer-bleresi">
              <table className="table_tvsh table ">
                <thead>
                  <tr>
                    <th>Tipi</th>
                    <th>TVSH</th>
                    <th>Vlera pa TVSH</th>
                    <th>Vlera me TVSH</th>
                  </tr>
                </thead>
                <tbody>{DisplayDataTable2}</tbody>
              </table>
            </div>
          </td>
        </tr>
      )}

      {fatureThjesht && state.NSLF && (
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>

            <tr>
              <td>NSLF: {state?.NSLF}</td>
            </tr>
            <tr>
              <td>NIVF: {state?.NIVF}</td>
            </tr>
            {/* <tr> */}
            <td
              style={{
                textAlign: "center",
                paddingTop: "5px",
                paddingBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <QRCode
                  style={{ width: 110, height: 110 }}
                  value={state.fisVerificationUrl}
                />
              </Box>
            </td>
            {/* </tr> */}
          </td>
        </tr>
      )}
      <tfoot>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td colSpan={5}>Gjeneruar nga Parid Smart Solution</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicTableSkontrino80;
