import React, { useEffect, useState, useRef } from "react";
// Importing necessary styles and components
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Routes, Route } from "react-router-dom";
// import WifiOffIcon from "@mui/icons-material/WifiOff";
// import { Button } from "@mui/material";
import FatureBlerje from "./pages/fature-blerje/FatureBlerjePrv";
import FleteDaljePrv from "./pages/flete-dalje/FleteDaljePrv";
import FleteHyrjePrv from "./pages/flete-hyrje/FleteHyrjePrv";
import Artikuj from "./pages/paneliIartikujve/Artikuj";
// import Register from "./components/authenticate/register/Register";
// import Forgot from "./components/authenticate/forgot/Forgot";
import { AxiosInterceptor } from "./services/axios";
import AuthorizedRoute from "./services/AuthorizedRoute";
import Supermarket from "./pages/POS/SuperMarket/NewSuperMarket/Supermarket";
import KlientC from "./pages/celje/KlientC";
import Restaurant from "./pages/POS/Restaurant/Restaurant";
import FatureShitje from "./pages/fatureShitje/FatureShitje";
import Fiskale from "./pages/fiskale/Fiskale";
import TabelaTransportuesi from "./pages/celje/TransportuesiC/TabelaTransportuesi";
import ArkaC from "./pages/celje/ListaArkave/ArkaC";
import BankaC from "./pages/celje/ListaBankave/BankaC";
import Login from "./components/authenticate/signIn/Login";
import PerdoruesC from "./pages/celje/PerdoruesC";
import FurnitorC from "./pages/celje/Furnitor/FurnitorC";
import AgjentiC from "./pages/celje/AgjentiC";
// import NavBar from "./components/Navbar/NavBar";
import NotFound from "./pages/NotFound/NotFound";
import FloatingActionButtons from "./Status";
import TabelaMagazina from "./pages/celje/Magazina/TabelaMagazina";
import { Toast } from "primereact/toast";
import { MyProvider } from "./components/context/MyStatePOSContext";
import { ApiProvider } from "./components/context/NdermarrjeInfo";
import Ndermarrje from "./pages/ndermarrje/Ndermarrje";

import Arka from "./pages/pagesat/Arka/Arka";
import Banka from "./pages/pagesat/Banka/Banka";
import { MyProviderArt } from "./components/context/ArtikullContextPosBar";
import { FtshProvider } from "./components/context/FtshState";
import PhoneNav from "./components/footerPhone/PhoneNav";
import { FtbProvider } from "./components/context/FtbState";

import MainRoutes from "./routes/MainRoutes";
import ThemeCustomization from "./themes";

// Main App component
function App() {
  // State variables
  const [hidePupUp, setHidePupUp] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [currentAction, setCurrentAction] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [userAutomatikisht, setUserAutomatikisht] = useState("");
  const [dataTime, setDataCurrentOffline] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  // // Effect to listen for online/offline events
  // useEffect(() => {
  //   const handleOnlineStatusChange = () => {
  //     setIsOnline(window.navigator.onLine);
  //   };

  //   window.addEventListener("online", handleOnlineStatusChange);
  //   window.addEventListener("offline", handleOnlineStatusChange);

  //   // Cleanup function to remove event listeners
  //   return () => {
  //     window.removeEventListener("online", handleOnlineStatusChange);
  //     window.removeEventListener("offline", handleOnlineStatusChange);
  //   };
  // }, []);

  // Toast Notification dynamic
  const toast = useRef(null);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const checkDisabled = () => {
    if (disabled) {
      showToast("Kliko Shtim ose Modifikim");
    }
  };

  // Main render
  return (
    <>
      <FloatingActionButtons isOnline={isOnline} />

      <AxiosInterceptor>
        <Toast ref={toast} />

        <div className="app">
          <Routes>
            {/* Landing Page Routes */}

            <Route
              path="/login"
              element={<Login setUserAutomatikisht={setUserAutomatikisht} />}
            />
            <Route
              path="/"
              element={<Login setUserAutomatikisht={setUserAutomatikisht} />}
            />
            {/* Main Dashboard Routes */}
            <Route path={MainRoutes.path} element={MainRoutes.element}>
              {MainRoutes.children.map((childRoute, index) => (
                <Route
                  key={index}
                  path={childRoute.path}
                  element={childRoute.element}
                >
                  {childRoute.children &&
                    childRoute.children.map((subChildRoute, subIndex) => (
                      <Route
                        key={subIndex}
                        path={subChildRoute.path}
                        element={subChildRoute.element}
                      />
                    ))}
                </Route>
              ))}
            </Route>

            {/* <Route path="/forgot" element={<Forgot />} /> */}
            {/* <Route path="/Register" element={<Register />} /> */}

            {/* POS Routes */}
            <Route
              path="/Pos/bar"
              element={
                <AuthorizedRoute>
                  <MyProvider>
                    <MyProviderArt>
                      <ApiProvider>
                        <Restaurant
                          isMobile={isMobile}
                          userAutomatikisht={userAutomatikisht}
                          setUserAutomatikisht={setUserAutomatikisht}
                          isOnline={isOnline}
                          // storeOfflineRequest={storeOfflineRequest}
                        />
                      </ApiProvider>
                    </MyProviderArt>
                  </MyProvider>
                </AuthorizedRoute>
              }
            />
            <Route
              path="/Pos/market"
              element={
                <AuthorizedRoute>
                  <MyProvider>
                    <ApiProvider>
                      <Supermarket
                        userAutomatikisht={userAutomatikisht}
                        setUserAutomatikisht={setUserAutomatikisht}
                      />
                    </ApiProvider>
                  </MyProvider>
                </AuthorizedRoute>
              }
            />

            {/* Other Routes */}
            <Route
              path="/fature-blerje"
              element={
                <AuthorizedRoute>
                  <FtbProvider>
                    <FatureBlerje
                      toast={toast}
                      showToast={showToast}
                      checkDisabled={checkDisabled}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      hidePupUp={hidePupUp}
                      setHidePupUp={setHidePupUp}
                      currentAction={currentAction}
                      setCurrentAction={setCurrentAction}
                      isOnline={isOnline}
                      isMobile={isMobile}
                    />
                  </FtbProvider>
                </AuthorizedRoute>
              }
            />
            <Route
              path="/fature-shitje"
              element={
                <AuthorizedRoute>
                  <FtshProvider>
                    <FatureShitje
                      toast={toast}
                      showToast={showToast}
                      hidePupUp={hidePupUp}
                      setHidePupUp={setHidePupUp}
                      roles={[1]}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      currentAction={currentAction}
                      setCurrentAction={setCurrentAction}
                      // storeOfflineRequest={storeOfflineRequest}
                      isOnline={isOnline}
                      // sendStoredRequests={sendStoredRequests}
                      setDataCurrentOffline={setDataCurrentOffline}
                      dataTime={dataTime}
                      isMobile={isMobile}
                    />
                  </FtshProvider>
                </AuthorizedRoute>
              }
            />

            <Route
              path="/Artikuj"
              element={
                <AuthorizedRoute>
                  <Artikuj
                    disabled={disabled}
                    setDisabled={setDisabled}
                    hidePupUp={hidePupUp}
                    setHidePupUp={setHidePupUp}
                    isMobile={isMobile}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                  />
                </AuthorizedRoute>
              }
            />

            <Route
              path={"/arka"}
              element={
                <AuthorizedRoute>
                  <Arka
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />

            <Route
              path={"/banka"}
              element={
                <AuthorizedRoute>
                  <Banka
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />

            <Route
              path={"/fiskale"}
              element={
                <AuthorizedRoute>
                  <Fiskale isMobile={isMobile} />
                </AuthorizedRoute>
              }
            />

            <Route
              path={"/ndermarrje"}
              element={
                <AuthorizedRoute>
                  <Ndermarrje isMobile={isMobile} />
                </AuthorizedRoute>
              }
            />

            <Route
              path="/flete-dalje"
              element={
                <AuthorizedRoute>
                  <FleteDaljePrv
                    disabled={disabled}
                    setDisabled={setDisabled}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    // storeOfflineRequest={storeOfflineRequest}
                    isOnline={isOnline}
                    // sendStoredRequests={sendStoredRequests}
                    setDataCurrentOffline={setDataCurrentOffline}
                    dataTime={dataTime}
                    isMobile={isMobile}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/flete-hyrje"
              element={
                <AuthorizedRoute>
                  <FleteHyrjePrv
                    disabled={disabled}
                    setDisabled={setDisabled}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isOnline={isOnline}
                    isMobile={isMobile}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/perdorues"
              element={
                <AuthorizedRoute>
                  <PerdoruesC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/klienti"
              element={
                <AuthorizedRoute>
                  <KlientC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/furnitori"
              element={
                <AuthorizedRoute>
                  <FurnitorC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/transportuesi"
              element={
                <AuthorizedRoute>
                  <TabelaTransportuesi
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    isMobile={isMobile}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/magazina"
              element={
                <AuthorizedRoute>
                  <TabelaMagazina
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    isMobile={isMobile}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/arka"
              element={
                <AuthorizedRoute>
                  <ArkaC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/banka"
              element={
                <AuthorizedRoute>
                  <BankaC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />
            <Route
              path="/celje/agjent"
              element={
                <AuthorizedRoute>
                  <AgjentiC
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    isMobile={isMobile}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </AuthorizedRoute>
              }
            />

            <Route
              path="*"
              element={
                <AuthorizedRoute>
                  <NotFound />
                </AuthorizedRoute>
              }
            />
          </Routes>
          {/* {isMobile && <PhoneNav />} */}
        </div>
      </AxiosInterceptor>
    </>
  );
}

// Exporting the App component
export default App;
