import { useEffect } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import navigation from "./menu-items";
import Loader from "../../components/Loader";
import Breadcrumbs from "../../components/@extended/Breadcrumbs";

import { handlerDrawerOpen, useGetMenuMaster } from "./api/menu";

// ==============================|| MAIN LAYOUT ||============================== //

export default function DashboardLayout() {
  const { menuMasterLoading } = useGetMenuMaster();
  const downXL = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  useEffect(() => {
    handlerDrawerOpen(!downXL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      <Drawer />
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)", // Adjust width as needed
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          overflowY: "auto", // Allow vertical scrolling
          height: "100vh", // Ensure it spans the full viewport height
          backgroundColor: "#fafafb",
        }}
      >
        <Toolbar />
        <Breadcrumbs navigation={navigation} title />
        <Outlet />
      </Box>
    </Box>
  );
}
