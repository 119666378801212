import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import PrimeGridKF from "../PrimeGridKlient";
import useStorage from "../../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../../services/axios";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import PaneliTab2 from "../../fature-blerje/tabet/PaneliTab2";
import "../Celje.scss";
import { Toast } from "primereact/toast";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SmallButtonGroupC from "../SmallButtonGroupC";
import { getArkaList, getArkaBanka } from "../../../store/globalSlice";
import Footer from "../../../components/Navbar/Footer";
import TabTjeraC from "./TabTjeraC";
import MainTabCArka from "./MainTabCArka";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SidebarCelje from "../SideBarCelje";
import { useUser } from "../../../zustand/common";

const ArkaC = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const [lastData, setLastData] = useState({});
  const [lastKodi, setLastKodi] = useState("");
  const toast = useRef(null);

  // Redux: Get client data from global slice
  // const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const arkaListRedux = useSelector((state) => state.globalSlice.arkaList);

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        setdata(arkaListRedux);
        if (arkaListRedux.length > 0) {
          const lastKodi = arkaListRedux[arkaListRedux.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("A01"); // Start with F1 if there are no existing entries
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [arkaListRedux, hideLoading, showLoading]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "Id", title: "ID" },
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "NrLL", title: "Llogari" },
    { field: "TCRCode", title: "TCRCode" },
    { field: "Aktiv", title: "Aktiv" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatArka"
  );

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };
  let defaultState = {
    Aktiv: 1,
    EVlefshmeDeri: "",
    Kodi: `${lastKodi}`,
    Magazina_Kodi: "",
    Mon: "",
    NrLL: "531",
    Pershkrim: "",
    PikaShitjes_Kodi: "",
    Punonjes_Id: null,
    Rendit: null,
    Selektuar: null,
    Sportel_kodi: "",
    TCRCode: "",
    TCRID: null,
    ValidFrom: "",
    ValidTo: "",
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [visibleRight, setVisibleRight] = useState(false);

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Arka
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SidebarCelje
          currentAction={currentAction}
          toast={toast}
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        />
      </div>
    );
  };

  const deleteMethod = async (e) => {
    if (state.TCRCode) {
      toast.current.show({
        severity: "info",
        summary: "Nuk mund te fshihet (Arka eshte fiskale)",
      });
      return;
    }
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=ARKA_LIST&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });

        return;
      } else {
        const response = await mainAxios.delete(`/arka/list/${state.Id}`);

        if (response && response.status === 200) {
          setState(defaultState);
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getArkaList("/arka/list"));
          toast.current.show({
            severity: "info",
            summary: "u fshi",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u fshi." + error.message,
      });
    }
  };

  const handleAdd = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Mon.trim()
    ) {
      toast.current.show({
        severity: "error",
        summary:
          "Plotesoni fushat e kerkuara: " +
          (!state.Kodi.trim() ? "Kodi, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
          (!state.NrLL.trim() ? "Llogari, " : "") +
          (!state.Mon.trim() ? "Monedha" : ""),
      });
      return;
    }
    try {
      const response = await mainAxios.post(`/arka/list`, {
        ...state,
        Aktiv: Number(state.Aktiv),
        EVlefshmeDeri: formatDate(state.EVlefshmeDeri, true),
        Kodi: state.Kodi,
        Magazina_Kodi: state.Magazina_Kodi,
        Mon: state.Mon,
        NrLL: state.NrLL,
        Pershkrim: state.Pershkrim,
        PikaShitjes_Kodi: state.PikaShitjes_Kodi,
        Punonjes_Id: Number(state.Punonjes_Id),
        Rendit: Number(state.Rendit),
        Selektuar: Number(state.Selektuar),
        Sportel_kodi: state.Sportel_kodi,
        TCRCode: state.TCRCode,
        TCRID: Number(state.TCRID),
        ValidFrom: state.ValidFrom,
        ValidTo: state.ValidTo,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getArkaList("/arka/list"));
        dispatch(getArkaBanka("/arbalist"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U rregjistrua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u regjistrua." + error.message,
      });
    }
  };

  const handleModify = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Mon.trim()
    ) {
      toast.current.show({
        severity: "error",
        summary:
          "Plotesoni fushat e kerkuara: " +
          (!state.Kodi.trim() ? "Kodi, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
          (!state.NrLL.trim() ? "Llogari, " : "") +
          (!state.Mon.trim() ? "Monedha" : ""),
      });
      return;
    }
    try {
      const response = await mainAxios.put(`/arka/list/${state.Id}`, {
        ...state,
        Aktiv: Number(state.Aktiv),
        EVlefshmeDeri: formatDate(state.EVlefshmeDeri, true),
        Kodi: state.Kodi,
        Magazina_Kodi: state.Magazina_Kodi,
        Mon: state.Mon,
        NrLL: state.NrLL,
        Pershkrim: state.Pershkrim,
        PikaShitjes_Kodi: state.PikaShitjes_Kodi,
        Punonjes_Id: Number(state.Punonjes_Id),
        Rendit: Number(state.Rendit),
        Selektuar: Number(state.Selektuar),
        Sportel_kodi: state.Sportel_kodi,
        TCRCode: state.TCRCode,
        TCRID: Number(state.TCRID),
        ValidFrom: state.ValidFrom,
        ValidTo: state.ValidTo,
      });

      if (response && response.status === 200) {
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getArkaList("/arka/list"));
        dispatch(getArkaBanka("/arbalist"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U modifikua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u modifikua." + error.message,
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.Id}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
      />
    </div>
  );

  const TabiKryesor = (
    <MainTabCArka
      TextTCRCode={true}
      state={state}
      disabled={disabled}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AccountBalanceWalletIcon />}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraC
        TextData={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        formatDate={formatDate}
        state={state}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh-c">
      <NavBar />
      <Toast ref={toast} />
      <div className="template">
        {!isMobile && <SideBar />}
        <div className="p-1 main-container-c">
          <div className="top_panel_fsh-c">
            <Panel headerTemplate={template} toggleable>
              <div className="bg-white" style={{ height: "100%" }}>
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve-c">
                      <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                      <div className="paneli-fis-print-c"></div>
                    </div>
                    <div className={"below-tabs-c"}>
                      <div className="below-tabs1-c">{TabiKryesor}</div>
                      <div className="below-tabs2-c">{smallTabsKM}</div>
                      <div className="below-tabs3-c"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "5px",
                      }}
                    >
                      {PaneliKryesor}
                    </div>
                    <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 5px",
                      }}
                    >
                      {PaneliDytesor}
                    </div>
                    <div style={{ margin: "5px" }}>{smallTabsKM}</div>
                  </>
                )}
              </div>
            </Panel>
          </div>
          <div className="middle_fsh-c"></div>

          <div className="dataTable_fsh-c">
            <PrimeGridKF
              gridKey="Arka"
              data={data}
              isMobile={isMobile}
              defaultState={defaultState}
              isEditable={true}
              defaultColumnsStorage={defaultColumnsStorage}
              columns={columns}
              setColumns={setColumns}
              disabled={disabled}
              setState={setState}
              rows={rows}
              setdata={setdata}
              state={state}
              setRows={setRows}
              selectedColumns={selectedColumns}
              setselectedcolumns={setselectedcolumns}
              setLastData={setLastData}
              currentAction={currentAction}
            />
          </div>
        </div>
      </div>
      {!isMobile && <Footer className="footer-position" />}
    </div>
  );
};

export default memo(ArkaC);
