import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import Dashboard from "../pages/dashboard/Dashboard";

import ThemeCustomization from "../themes";
import ScrollTop from "../components/ScrollTop";

const Color = Loadable(lazy(() => import("../pages/component-overview/color")));
const Typography = Loadable(
  lazy(() => import("../pages/component-overview/typography"))
);
const Shadow = Loadable(
  lazy(() => import("../pages/component-overview/shadows"))
);
const DashboardDefault = Loadable(
  lazy(() => import("../pages/dashboard/dashboard/index"))
);

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("../pages/extra-pages/sample-page"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: (
    // <ThemeCustomization>
    //   <ScrollTop>
    <Dashboard />
    // </ScrollTop>
    // </ThemeCustomization>
  ),
  children: [
    {
      path: "/dashboard",
      element: (
        // <ThemeCustomization>
        // <ScrollTop>
        <DashboardDefault />
        // </ScrollTop>
        // </ThemeCustomization>
      ),
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
  ],
};

export default MainRoutes;
