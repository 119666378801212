import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LoadingOverlayProvider } from "./components/LoadingOverlay";
import "./index.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { store } from "./store/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <LoadingOverlayProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LoadingOverlayProvider>
  </Provider>,
  rootElement
);

// Reporting web vitals
reportWebVitals(console.log);
