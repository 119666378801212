import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavStyles/NavBar.scss";
import Logoja from "../../assets/Logoja.png";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import { useUser } from "../../zustand/common";
import Xhiro from "../../pages/MbylljeTurni/Xhiro";
import { useNavigate } from "react-router-dom";
import items from "./items";
import MenuIcon from "@mui/icons-material/Menu";
import "./NavStyles/posBtn.scss";
import SideBarPhone from "./SideBarPhone";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

// const CustomLink = ({ to, children, currentAction, isActive }) => {
//   const navigate = useNavigate();
//   const checkBeforeNavigate = (url) => {
//     if (currentAction === "fromArt") {
//       alert("Uncheck: Modifiko ne Tabele.");
//       return;
//     }
//     if (currentAction === "modify" || currentAction === "add") {
//       alert("Klikoni: Regjistro ose Anullim per te vazhdur.");
//       return;
//     } else {
//       navigate(url);
//     }
//   };

//   return (
//     <div
//       className={`navitem ${isActive ? "active" : ""}`}
//       onClick={() => checkBeforeNavigate(to)}
//       style={{ color: isActive ? "#1564ad" : "white", cursor: "pointer" }}
//     >
//       {children}
//     </div>
//   );
// };

const NavBar = ({
  setCurrentRoute,
  currentAction,
  setCurrentAction,
  setDisabled,
}) => {
  const { user, logout } = useUser();
  const username = user.username;
  const nipt = user.nipt;
  const [logoutbtn, setLogout] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isXhiroDialogOpen, setIsXhiroDialogOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  // Reference to the dropdown element
  const dropdownRef = useRef(null);

  const handleDropdownClick = (id) => {
    // Toggle dropdown for clicked item
    if (openDropdown === id) {
      setOpenDropdown(null); // Close if already open
    } else {
      setOpenDropdown(id); // Open the clicked one
    }
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of the dropdown or sublinks
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".sublink-list")
    ) {
      setOpenDropdown(null); // Close the dropdown
    }
  };

  useEffect(() => {
    if (openDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const checkBeforeNavigate = (url) => {
    if (currentAction === "modify" || currentAction === "add") {
      alert("Klikoni: Regjistro ose Anullim per te vazhdur.");
      return;
    } else {
      if (currentAction === "fromArt") {
        setCurrentAction(null);
        setDisabled(true);
      }
      navigate(url);
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [setCurrentRoute]);

  useEffect(() => {
    if (!logoutbtn) {
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

      const handleUnload = () => {
        navigate("/login");
        logout();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    } else {
      logout();
    }
  }, [logout, logoutbtn]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTurni = () => {
    setIsXhiroDialogOpen(true);
  };

  const handleSublinkClick = (sublink) => {
    if (sublink.id === 4.3) {
      handleOpenTurni();
    } else {
      checkBeforeNavigate(sublink.url);
    }
    setOpenDropdown(null);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const handleMenuButtonClick = () => {
    setSidebarOpen(true);
  };
  return (
    <div
      style={{ background: "#1564ad" }}
      className="navBarMain tekst flex items-center shadow-md "
    >
      {isMobile && (
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#bdbdbd" }}
              >
                <Avatar sx={{ width: 25, height: 25 }} />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {!isMobile && (
        <div className="item-1">
          <Link
            to="/fature-shitje"
            className="flex"
            style={{ textAlign: "center" }}
          >
            <img src={Logoja} className="pss-logo ml-8 h-9 w-9" alt="" />
            {/* <span
              className="ml-2 flex items-center"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              Dashboard
            </span> */}
          </Link>
        </div>
      )}

      {!isMobile && (
        <div className="item-2">
          <ul className="item-2-ul flex gap-1">
            {items.map((item) => {
              const { id, text, url, photo, sublinks } = item;
              const isActive = location.pathname === url;

              return (
                <li key={id} className={"nav-item"} ref={dropdownRef}>
                  <div
                    className={`navitem ${sublinks ? "has-dropdown" : ""} ${
                      isActive ? "active" : ""
                    }`}
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() =>
                      sublinks
                        ? handleDropdownClick(id)
                        : checkBeforeNavigate(url)
                    }
                  >
                    {photo}
                    {text}
                  </div>

                  {/* Render sublinks if the item has sublinks and the dropdown is open */}
                  {sublinks && openDropdown === id && (
                    <ul className="sublink-list">
                      {sublinks.map((sublink) => (
                        <li key={sublink.id}>
                          <div
                            className="sublink-item"
                            onClick={() => handleSublinkClick(sublink)}
                          >
                            {sublink.photo}
                            {sublink.text}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {!isMobile && (
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#bdbdbd" }}
              >
                <Avatar sx={{ width: 25, height: 25 }} />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        username={username}
        nipt={nipt}
        onSignOut={() => setLogout(true)}
      />
      {isMobile && (
        <div className="navbar-button item-4">
          <SideBarPhone
            sidebarOpen={sidebarOpen}
            setOpen={setSidebarOpen}
            onClose={() => setSidebarOpen(false)}
          />
        </div>
      )}
      {/* Dialog POS */}
      {isMobile && (
        <IconButton onClick={handleMenuButtonClick} className="menu_btn">
          <MenuIcon style={{ color: "white" }} />
        </IconButton>
      )}
      {isXhiroDialogOpen && (
        <Xhiro
          homeNavbar={true}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          open={isXhiroDialogOpen}
          onClose={() => setIsXhiroDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default NavBar;
