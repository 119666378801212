import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const LoadingOverlayContext = React.createContext();

export const LoadingOverlayProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);

  // Create a custom theme with a higher zIndex for the backdrop
  const theme = createTheme({
    customShadows: {
      z1: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Example shadow
    },
    zIndex: {
      backdrop: 1400, // Higher than the dialog's zIndex
    },
  });

  return (
    <LoadingOverlayContext.Provider value={{ showLoading, hideLoading }}>
      <ThemeProvider theme={theme}>
        {children}
        <Backdrop
          sx={{ color: "#fff", zIndex: theme.zIndex.backdrop }}
          open={loading}
          // onClick={hideLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </LoadingOverlayContext.Provider>
  );
};
